import { defineStore } from 'pinia';
import { useAgreementEditorStore } from '@/components/AgreementEditor/stores/agreement-editor-store';

export const useSignersStore = defineStore('signers', {
    state: () => ({
        agreementEditorStore: useAgreementEditorStore(),
        signersMaxCount: 25,
        documentInputs: [],
        signerInputs1: [],
        signerInputs2: [],
        signerInputs3: [],
        signerInputs4: [],
        signerInputs5: [],
        signerInputs6: [],
        signerInputs7: [],
        signerInputs8: [],
        signerInputs9: [],
        signerInputs10: [],
        signerInputs11: [],
        signerInputs12: [],
        signerInputs13: [],
        signerInputs14: [],
        signerInputs15: [],
        signerInputs16: [],
        signerInputs17: [],
        signerInputs18: [],
        signerInputs19: [],
        signerInputs20: [],
        signerInputs21: [],
        signerInputs22: [],
        signerInputs23: [],
        signerInputs24: [],
        signerInputs25: [],

    }),
    getters: {
        allAgreementInputs: (state) => {
            return [
                ...state.documentInputs,
                ...state.signerInputs1,
                ...state.signerInputs2,
                ...state.signerInputs3,
                ...state.signerInputs4,
                ...state.signerInputs5,
                ...state.signerInputs6,
                ...state.signerInputs7,
                ...state.signerInputs8,
                ...state.signerInputs9,
                ...state.signerInputs10,
                ...state.signerInputs11,
                ...state.signerInputs12,
                ...state.signerInputs13,
                ...state.signerInputs14,
                ...state.signerInputs15,
                ...state.signerInputs16,
                ...state.signerInputs17,
                ...state.signerInputs18,
                ...state.signerInputs19,
                ...state.signerInputs20,
                ...state.signerInputs21,
                ...state.signerInputs22,
                ...state.signerInputs23,
                ...state.signerInputs24,
                ...state.signerInputs25,
            ];
        },

        allSignerInputs: (state) => {
            return [
                ...state.filterInputs(state.signerInputs1),
                ...state.filterInputs(state.signerInputs2),
                ...state.filterInputs(state.signerInputs3),
                ...state.filterInputs(state.signerInputs4),
                ...state.filterInputs(state.signerInputs5),
                ...state.filterInputs(state.signerInputs6),
                ...state.filterInputs(state.signerInputs7),
                ...state.filterInputs(state.signerInputs8),
                ...state.filterInputs(state.signerInputs9),
                ...state.filterInputs(state.signerInputs10),
                ...state.filterInputs(state.signerInputs11),
                ...state.filterInputs(state.signerInputs12),
                ...state.filterInputs(state.signerInputs13),
                ...state.filterInputs(state.signerInputs14),
                ...state.filterInputs(state.signerInputs15),
                ...state.filterInputs(state.signerInputs16),
                ...state.filterInputs(state.signerInputs17),
                ...state.filterInputs(state.signerInputs18),
                ...state.filterInputs(state.signerInputs19),
                ...state.filterInputs(state.signerInputs20),
                ...state.filterInputs(state.signerInputs21),
                ...state.filterInputs(state.signerInputs22),
                ...state.filterInputs(state.signerInputs23),
                ...state.filterInputs(state.signerInputs24),
                ...state.filterInputs(state.signerInputs25),
            ];
        },

        allSignerInputsList: (state) => {
            return [
                state.filterInputsByProvider(state.signerInputs1),
                state.filterInputsByProvider(state.signerInputs2),
                state.filterInputsByProvider(state.signerInputs3),
                state.filterInputsByProvider(state.signerInputs4),
                state.filterInputsByProvider(state.signerInputs5),
                state.filterInputsByProvider(state.signerInputs6),
                state.filterInputsByProvider(state.signerInputs7),
                state.filterInputsByProvider(state.signerInputs8),
                state.filterInputsByProvider(state.signerInputs9),
                state.filterInputsByProvider(state.signerInputs10),
                state.filterInputsByProvider(state.signerInputs11),
                state.filterInputsByProvider(state.signerInputs12),
                state.filterInputsByProvider(state.signerInputs13),
                state.filterInputsByProvider(state.signerInputs14),
                state.filterInputsByProvider(state.signerInputs15),
                state.filterInputsByProvider(state.signerInputs16),
                state.filterInputsByProvider(state.signerInputs17),
                state.filterInputsByProvider(state.signerInputs18),
                state.filterInputsByProvider(state.signerInputs19),
                state.filterInputsByProvider(state.signerInputs20),
                state.filterInputsByProvider(state.signerInputs21),
                state.filterInputsByProvider(state.signerInputs22),
                state.filterInputsByProvider(state.signerInputs23),
                state.filterInputsByProvider(state.signerInputs24),
                state.filterInputsByProvider(state.signerInputs25),
            ];
        },
    },
    actions: {
        filterInputs(inputsList) {
            return this.agreementEditorStore.filterAgreementInputs(inputsList);
        },
        filterInputsByProvider(inputsList) {
          return this.agreementEditorStore.filterAgreementInputsByProvider(inputsList);
        }
    },
});
